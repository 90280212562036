import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110')
];

export const server_loads = [0,2,14,15,16,3,4,6,7,10];

export const dictionary = {
		"/(admin)": [~17,[2]],
		"/api/v1/orders/[id]/print": [87],
		"/api/v1/tickets/[id]/download": [88],
		"/api/v1/tickets/[id]/print": [89],
		"/(admin)/discounts": [~18,[2]],
		"/(admin)/floorplans": [19,[2]],
		"/(admin)/getting-started": [~20,[2]],
		"/global-office": [~90,[14]],
		"/global-office/accounting": [~91,[14]],
		"/global-office/seller-requests": [~92,[14]],
		"/global-office/seller-requests/[request]": [~93,[14]],
		"/login": [~94,[15]],
		"/login/unauth": [~95,[15]],
		"/logout": [~96],
		"/(admin)/misc/under-construction": [21],
		"/office": [~97,[16]],
		"/office/accounting": [~98,[16]],
		"/office/boxoffice": [~99,[16]],
		"/office/boxoffice/external-screen": [~100],
		"/office/cart": [~101,[16]],
		"/office/checkout": [~102,[16]],
		"/office/editions/[editionId]/note": [103,[16]],
		"/office/events/[eventId]": [~104,[16]],
		"/office/orders": [~105,[16]],
		"/office/orders/[order]": [~106,[16]],
		"/office/settings": [~107,[16]],
		"/office/turnover": [~108,[16]],
		"/scan": [~109],
		"/scan/pda": [~110],
		"/(admin)/series": [22,[2]],
		"/(admin)/series/[series]": [23,[2,3]],
		"/(admin)/series/[series]/discounts": [~24,[2,3]],
		"/(admin)/series/[series]/editions/[edition]": [~25,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/billing": [~26,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/billing/cs/f": [~27,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/discounts": [~28,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/events": [~29,[2,3,4,5]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]": [~30,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/capacities": [~31,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/discounts": [~32,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/floorplan": [~33,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/graphics": [34,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/notesForOffices": [~35,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/products": [~36,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/products/[product]/order": [~37,[2,3,4,5,6,7]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/products/[product]/settings": [~38,[2,3,4,5,6,7]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/settings": [~39,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics": [~40,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/rules": [~41,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/rules/[ticket]": [~42,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/sales": [~43,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/scans": [~44,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/tickets": [~45,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/offices": [~46,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/offices/notes": [~47,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/orders": [~48,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/orders/[order]": [~49,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/sales": [~50,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/sales/festival/[festival]": [~53,[2,3,4,10]],
		"/(admin)/series/[series]/editions/[edition]/sales/festival/[festival]/daily": [~54,[2,3,4,10]],
		"/(admin)/series/[series]/editions/[edition]/sales/festival/[festival]/history": [~55,[2,3,4,10]],
		"/(admin)/series/[series]/editions/[edition]/sales/(edition)/offices": [~51,[2,3,4,9]],
		"/(admin)/series/[series]/editions/[edition]/sales/(edition)/performance": [~52,[2,3,4,9]],
		"/(admin)/series/[series]/settings": [56,[2,3]],
		"/(admin)/settings": [57,[2,11]],
		"/(admin)/settings/about": [~58,[2,11]],
		"/(admin)/settings/access-policies": [~59,[2,11]],
		"/(admin)/settings/colors": [60,[2,11]],
		"/(admin)/settings/cs/f/live": [~61,[2,12]],
		"/(admin)/settings/cs/f/live/charts/by-day": [~62,[2,12,13]],
		"/(admin)/settings/cs/f/live/charts/sales": [~63,[2,12,13]],
		"/(admin)/settings/cs/f/live/charts/scans": [~64,[2,12,13]],
		"/(admin)/settings/cs/f/live/charts/today": [~65,[2,12,13]],
		"/(admin)/settings/cs/f/live/numbers": [~66,[2,12]],
		"/(admin)/settings/exhibitorportal": [~67,[2,11]],
		"/(admin)/settings/exhibitorportal/[exhibitor]": [~68,[2,11]],
		"/(admin)/settings/invoices": [~69,[2,11]],
		"/(admin)/settings/layouts": [~70,[2,11]],
		"/(admin)/settings/layouts/[layout]": [~71,[2]],
		"/(admin)/settings/limits": [~72,[2,11]],
		"/(admin)/settings/linked-events": [~73,[2,11]],
		"/(admin)/settings/logos": [~74,[2,11]],
		"/(admin)/settings/misc": [~75,[2,11]],
		"/(admin)/settings/offices": [~76,[2,11]],
		"/(admin)/settings/offices/[office]": [~77,[2,11]],
		"/(admin)/settings/offices/[office]/qr": [~78,[2,11]],
		"/(admin)/settings/roles": [~79,[2,11]],
		"/(admin)/settings/sponsors": [~80,[2,11]],
		"/(admin)/settings/stripe": [~81,[2,11]],
		"/(admin)/settings/stripe/cash-point-closings": [~82,[2,11]],
		"/(admin)/settings/users": [~84,[2,11]],
		"/(admin)/settings/users/[user]/qr": [~85,[2,11]],
		"/(admin)/settings/user": [83,[2,11]],
		"/(admin)/settings/venues": [~86,[2,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';